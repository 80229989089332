@import "colors";

.selectUser {
    background-color: $off-white;
    padding: 8px;

    .row {
        margin: 0; }

    .userToSelect:hover {
        background-color: $blue;
        color: white;
        cursor: pointer; } }

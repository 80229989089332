@import "colors";
@import "spacing";
@import "util";

$option-pane-bg: white;
$option-pane-left-padding: 3 * $std-space;
$option-pane-top-padding: 2 * $std-space;
$doc-title-left: 86px;

#docNav {
    #documentTitle {
        position: relative;
        margin-left: 0;
        padding: 8px 0;
        border-bottom: 1px solid $outline;
        margin-bottom: 16px;

        .textInput {
            margin-bottom: 0; }

        .textInput .displayMode {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                margin-left: 3 * $std-space;
                font-size: 14px;
                color: $grey; } } }

    .sectionPane {
        margin-left: 3 * $std-space;
        .navLevelHeading {
            margin-bottom: 0; } }

    .option {
        font-size: 16px;
        line-height: 26px;

        .textValue {
            cursor: pointer; }

        .textValue:hover {
            color: $orange; }

        input {} }

    .optionPane {
        padding: 16px;
        background-color: $option-pane-bg;
        box-shadow: 1px 1px 28px rgba(0, 0, 0,0.1);
        border-radius: 4px;
        border: 1px solid $outline; }

    .optionPane .optionTitle {}

    .optionPane .warnings {
        font-size: 14px;
        color: $warning;
        line-height: 14px; }

    .optionPane input {
        line-height: 1 !important;
        padding: 5px 0 5px 5px; }

    .optionPane .custom-control-input:disabled ~ .custom-control-label {
        text-decoration: line-through; }

    .optionPane .valueBiases {
        color: $lt-grey;
        margin-left: $std-space; }

    #docNav .option a {
        cursor: pointer; }

    .subOption {
        margin-left: 5 * $std-space;
        margin-bottom: $std-space; }

    .subOption .optionTitle {
        font-weight: 400;
        font-style: italic; }

    .tabs {
        padding-top: $std-space; } // make the tab selectors stand out more

    .tab {
        padding-top: 0; } // line up with the white background of the option pane

    .partyBtns {
        button {
            margin: 0 $std-space $std-space 0; } } }

// Pika Calendar Mods
.pika-label {
    text-decoration: underline;
    color: $blue; }

.pika-label:hover {
    color: $orange; }

@import "editControls";
@import "spacing";

#documentPageHook {
    #fullHeightColumnWrapper {
        display: flex;
        margin: 0 auto;
        width: 100%;
        background: $white;

        .fullHeightColumn {
            overflow-y: auto;
            position: relative;

            #docPane {
                max-width: 870px;
                margin: 0 auto;
                background: #fff;
                padding: 136px;
                box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
                min-height: 1050px;
                overflow: hidden;
                margin-bottom: 60px; } }

        #docNavWrapper {
            width: 525px;
            background-color: $white; }

        #docPaneWrapper {
            overflow-y: auto;
            padding: 0 15px;
            position: relative !important;
            flex-shrink: 1;
            width: calc(100% - 525px);
            background: #E6E6E6;
            margin: 0 auto;
            padding-top: 60px; } } }

#docNavWrapper::-webkit-scrollbar {
    width: 4px;
    display: block; }

#docNavWrapper::-webkit-scrollbar-track {
    background-color: $lt-blue; }

.editor-action-area {
    width: 100%;
    overflow-x: hidden;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;

    #docNavWrapper {
        width: 450px;
        z-index: 99;
        background: $editor-bg;
        overflow-y: auto;
        overflow-x: hidden;

        #docNav {
            padding: 2rem;
            margin-bottom: 6 * $std-space; } } }

#articleNav {
    width: fit-content;
    min-width: 460px;
    .topNav {
        .openable {
            background: transparent;
            margin-bottom: 0;
            border-radius: 4px;
            padding: 0; }

        .openable.isOpened {
            .sectionPane {
                margin-bottom: 24px; } } } }

.confirmationPopup {
    width: 500px;
    height: 300px;
    background: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .msgHeading {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px; }

    .msgBody {
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px; }

    .alertBtnsWrapper {
        margin-top: 40px;

        .cancelbtn {
            background: #E6EDFA;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #33475B;
            margin-right: 20px; }

        .deletebtn {
            background: #F67032;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF; } } }


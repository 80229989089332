@import "colors";
@import "util";
@import "spacing";

$background: $grey;

#controlsWrapper {
    padding: 14px 32px;
    background-color: $white;
    z-index: 999;
    position: relative;
    border-bottom: 1px solid $outline;

    .editor-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back-wrapper {
            display: flex;
            align-items: center;

            .page-back-btn {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E3E4E8;
                border-radius: 20px;
                margin-right: 8px;
                outline: none;
                border: none; } } }

    #previewWarning {
        color: $lt-green; }

    #controls {
        display: flex;
        justify-content: flex-end; }

    // https://stackoverflow.com/questions/37146655/using-flexbox-left-align-and-right-align-elements-in-one-row
    #leftControls {
        margin-right: auto;
        align-self: center; }

    .control {
        display: inline-block;
        align-self: center;
        margin-left: 16px; }

    .control:last-child {
        padding-right: 0; }

    a.link-btn {
        font-size: 14px;
        color: $grey;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 5px;
        border-radius: 5px;

        img {
            margin-right: 8px; } }
    a.link-btn:hover {
        text-decoration: none;
        background-color: #f3f3f3; }

    .dropdown-menu {
        background-color: lighten($background, 20); }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: lighten($background, 40); } }

#editControls {
    #editNum {
        margin: 0 8px;
        color: $text; }

    a {
        padding: 4px 8px;
        cursor: pointer; } }

#docPane .focusedEdit {
    background-color: rgba($orange, .2) !important; }

.templateDraftlensLogo {
    width: 228px; }

.savingDiv {
    width: 50px; }

.savingLoaderContainer {
    margin-left: -25px;
    height: 10px;
    margin-bottom: -80px; }

.savingLoader {
    position: absolute;
    width: 10px;
    height: 10px;
    margin-top: 10px; }

.savingLoader div {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 8px solid $orange;
    border-radius: 50%;
    animation: savingLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $orange transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s; }

    &:nth-child(2) {
        animation-delay: -0.3s; }

    &:nth-child(3) {
        animation-delay: -0.15s; } }

@keyframes savingLoader {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

.savingText {
    font-size: 16px;
    color: $grey;
    margin-top: 77px;
    margin-right: 10px;
    position: absolute; }

.headerBackground {
    background-image: linear-gradient(to right, #F66322, 50%, #DB4E1A); }

.shareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: #e67e52;
    height: 40px;
    border-radius: 5px;
    margin-right: -8px;
    margin-left: -30px; }

.shareButton:hover {
    text-decoration: none;
    background-color: #ea9774; }

.sharePreviewIcon {
    margin-left: -17px;
    margin-top: -3px; }

.fileNameEditPreview {
    margin-bottom: -11.5px;
    width: 320px; }

.fileNameEditPreview input {
    width: 100%;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 12px;
    background-color: transparent;
    margin-bottom: 12px;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    font-weight: 500;
    font-style: normal;
    transition: border 0.5s; }

.fileNameEditPreview input:focus-visible {
    border: 1px solid #FFFFFF;
    outline: #FFFFFF;
    border-radius: 5px; }

.fileNameEditPreview input:hover {
    border: 1px solid #FFFFFF;
    outline: #FFFFFF;
    border-radius: 5px; }

.documentDraftlensLogo {
    width: 228px; }

.previewDiv {
    width: 140px;
    height: 30px;
    border-radius: 5px;
    background-color: #FBEADA;
    color: #D12121;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
    margin-left: 13px; }

.previewDiv:hover .tooltip-text {
    visibility: visible;
    cursor: pointer; }

.tooltip-text {
    visibility: hidden;
    position: absolute;
    box-shadow: 0px 0px 4px #222;
    z-index: 1;
    width: 120px;
    height: 20px;
    bottom: -1.3em;
    color: #E5E5E5;
    font-size: 12px;
    background-color: #5F5F5F;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex; }

.tooltip-text::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #5F5F5F;
    bottom: 15px;
    padding: 5px;
    z-index: 1; }

.subscriptionPageHeader {
    height: 50px;
    background-image: linear-gradient(to right, #F66322, 50%, #DB4E1A);
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 10px; }

.subscriptionPageLogoDiv {
    padding-left: 50px;
    display: flex;
    align-items: center;
    flex-direction: row; }

.subscriptionPageLogo {
    margin-right: 50px; }

.subscriptionDraftlensLogo {
    width: 228px; }

.subscriptionPageMenuAnchor {
    text-decoration: none;
    margin-right: 20px;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: -17px; }

.subscriptionPageMenuAnchor:hover {
    text-decoration: none;
    color: #FFFFFF; }

.subscriptionPageButtonsRow {
    display: flex;
    align-items: center;
    flex-direction: row; }

.subscriptionPageHelpButton {
    border: none;
    border-radius: 5px;
    margin-right: 14px;
    background: #fbeee8;
    color: #4c5261;
    height: 35px;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    margin-top: -2px;
    display: flex;
    align-items: center;
    padding-left: 25px; }

.subscriptionPageHelpButton:hover {
    background-color: #fff;
    color: #4c5261;
    text-decoration: none; }

.subscriptionPageLogInButton {
    border: none;
    border-radius: 5px;
    margin-right: 14px;
    background-color: #fd6b35;
    color: #fff;
    height: 35px;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    margin-top: -2px;
    display: flex;
    align-items: center;
    padding-left: 20px; }

.subscriptionPageLogInButton:hover {
    background-color: #e67e52;
    color: #fff;
    text-decoration: none; }

.subscriptionPagePlansAndPricingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 110px; }

.subscriptionPagePlansAndPricing {
    font-family: "Mulish", sans-serif;
    font-size: 68px;
    color: #55545C;
    font-weight: 600; }

.subscriptionPagePricingSubtitle {
    font-family: "Mulish", sans-serif;
    font-size: 40px;
    color: #55545C; }

.subscriptionPageOuterButton {
    width: 365px;
    height: 55px;
    background-color: #fff;
    border: 1px solid #e2e4e7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    outline: none; }

.subscriptionPageOuterButton:focus {
    outline: none; }

.subscriptionPageSelectedButton {
    width: 175px;
    height: 37px;
    background-color: #e76510;
    border: none;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.subscriptionPageSelectedButton:focus {
    outline: none; }

.subscriptionPageUnselectedButton {
    width: 175px;
    height: 37px;
    background-color: transparent;
    border: none;
    color: #55545c;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.subscriptionPageUnselectedButton:focus {
    outline: none; }

.subscriptionPageBillingCycleDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 5px; }

.subscriptionPageBillingCycle {
    font-family: "Mulish", sans-serif;
    font-size: 20px; }

.subscriptionPageSaveOnYearly {
    color: #E86E0B;
    font-family: "Mulish", sans-serif;
    font-size: 20px; }

.subscriptionPagePlansDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 110px;
    margin-top: 100px; }

.subscriptionPageBasicPlan {
    border: 1px solid #E2E4E7;
    color: #FFFFFF;
    border-radius: 8px;
    width: 460px;
    height: 770px;
    padding-top: 40px;
    padding-bottom: 40px; }

.subscriptionPageTitleText {
    font-family: "Mulish", sans-serif;
    font-size: 40px;
    color: #55545C;
    display: flex;
    align-items: center;
    justify-content: center; }

.subscriptionPageTagLineText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #55545C;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 30px; }

.subscriptionPageSeparator {
    border: 1px solid #F66322; }

.subscriptionPageAmountText {
    font-family: "Mulish", sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: #F66322;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }

.subscriptionPageAmountTagLineText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #F66322;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    margin-bottom: 30px; }

.subscriptionPageGetStartedInActiveButton {
    border: none;
    border-radius: 5px;
    margin-top: 40px;
    background-color: #e9e9e9;
    color: #7e7e7e;
    height: 40px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30%; }

.subscriptionPageGetStartedInActiveButton:hover {
    text-decoration: none;
    background-color: #e9e9e9;
    color: #7e7e7e; }

.subscriptionPageGetStartedButton {
    border: none;
    border-radius: 5px;
    margin-top: 40px;
    background-color: #F66322;
    color: #fff;
    height: 40px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30%; }

.subscriptionPageGetStartedButton:hover {
    background-color: #e16225;
    color: #fff;
    text-decoration: none; }

.subscriptionPageFeatures {
    margin: 45px; }

.subscriptionPageIncludesText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #55545C;
    font-weight: bold; }

.subscriptionPageFeatureIcon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: -20px; }

.subscriptionPageFeatureDiv {
    display: flex;
    align-items: center;
    flex-direction: row; }

.subscriptionPageFeatureText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #55545C; }

.paymentSuccessButton {
    border: none;
    border-radius: 5px;
    background-color: #F66322;
    color: #fff;
    height: 40px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; }

.paymentSuccessButton:hover {
    background-color: #e16225;
    color: #fff;
    text-decoration: none; }

.paymentFailureCancelButton {
    border: none;
    border-radius: 5px;
    background-color: #F66322;
    color: #fff;
    height: 40px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; }

.paymentFailureCancelButton:hover {
    background-color: #e16225;
    color: #fff;
    text-decoration: none; }

.paymentFailureRetryButton {
    border: none;
    border-radius: 5px;
    background-color: #F66322;
    color: #fff;
    height: 40px;
    width: 200px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px; }

.paymentFailureRetryButton:hover {
    background-color: #e16225;
    color: #fff;
    text-decoration: none; }

.paymentStatusButtonsDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center; }

.sessionUrlLoadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw; }

.sessionUrlLoadingContainer {
    height: 150px;
    margin-left: -10%; }

.sessionUrlLoader {
    position: absolute;
    width: 150px;
    height: 150px;
    margin-top: 10px; }

.sessionUrlLoader div {
    position: absolute;
    width: 150px;
    height: 150px;
    border: 8px solid $orange;
    border-radius: 50%;
    animation: sessionUrlLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $orange transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s; }

    &:nth-child(2) {
        animation-delay: -0.3s; }

    &:nth-child(3) {
        animation-delay: -0.15s; } }

@keyframes sessionUrlLoader {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

.paymentStatusPageDiv {
    display: flex;
    width: 100%;
    height: 100vh; }

.backgroundImageStyle {
    width: 30%; }

.paymentSuccessTextDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 200px; }

.paymentSuccessText {
    font-family: "Mulish", sans-serif;
    color: #3F3F3F;
    font-weight: bold;
    font-size: 34px; }

.paymentSuccessRedirectText {
    font-family: "Mulish", sans-serif;
    color: #636161;
    font-size: 18px;
    font-weight: normal;
    margin-top: -10px; }

.anchorTagTextStyle {
    color: #636161;
    text-decoration: underline; }

.anchorTagTextStyle:hover {
    color: #F66322;
    text-decoration: underline; }

.emailVerificationMainDiv {
    display: flex;
    width: 100%;
    height: 100vh; }

.emailVerificationImageStyle {
    width: 30%; }

.emailVerificationContentDiv {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center; }

.emailVerificationContentCard {
    width: 700px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    background-color: #FFFFFF;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 35px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column; }

.emailVerificationDraftlensLogo {
    width: 228px; }

.emailVerificationHeadingText {
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #575757;
    font-size: 34px;
    margin-top: 40px;
    margin-bottom: 40px; }

.emailVerificationNormalText {
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    color: #575757;
    font-weight: normal;
    flex-direction: row;
    margin-bottom: 40px; }

emailPageVerificationNormalTextDiv {
    display: flex; }

.emailPageVerificationNormalText {
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    color: #575757;
    font-weight: normal; }

.checkEmail {
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    color: #575757;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px; }

.resendEmailButton {
    width: 150px;
    height: 40px;
    background-color: #F66322;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #FFF;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 40px; }

.resendEmailButton:hover {
    background-color: #FF966F; }

.resendEmailButton:focus {
    outline: none; }

.emailVerificationHelpText {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    color: #575757;
    font-weight: normal; }

.emailVerificationAnchorTag {
    color: #F66322;
    font-weight: bold; }

.emailVerificationAnchorTag:hover {
    color: #F66322;
    text-decoration: none; }

@import "sigPage";
@import "editControls";
@import "navHeadings";
@import "navPane";
@import "diff";
@import "splitPage";
@import "colors";
@import "spacing";
@import "permissions";
@import "selectUser";
@import "selectOrg";
@import "dropdown";
@import "fonts";

#viewDocumentPage {
    #docPaneWrapper {
        #docPane, #docPane p, #docPane li {
            font-size: 18px !important; }

        #docPane {
            font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif !important;
            padding: 4 * $std-space;
            padding-bottom: $std-space * 2;
            white-space: pre-wrap;

            p, .def {
                text-indent: $nodeMargin; }

            p, li, div {
                line-height: $std-space * 3;
                // div with no content has no height
                min-height: $std-space * 3; }

            // List stuff
            ol {
                counter-reset: list;
                list-style-position: inside;
                padding-left: 0; }

            @mixin li-indent($multiple) {
                text-indent: ($nodeMargin * 2) + ($nodeMargin * $multiple); }

            li {
                list-style: none; }

            li > * {
                @include li-indent(0); }

            li > *:before {
                position: relative;
                right: $std-space;
                display: inline-block;
                min-width: $nodeMargin;
                text-indent: 0; }

            li > ol {
                margin-top: 1em; }

            ol > li > *:first-child:before {
                content: "(" counter(list, lower-alpha) ")";
                counter-increment: list; }

            li > ol > li > * {
                @include li-indent(1); }

            li > ol > li > *:first-child:before {
                content: "(" counter(list, lower-roman) ")";
                counter-increment: list; }

            li > ol > li > ol > li > * {
                @include li-indent(2); }

            li > ol > li > ol > li > *:first-child:before {
                content: "(" counter(list) ")";
                counter-increment: list; }

            ol[inline-list] {
                margin: 0;
                padding: 0;
                display: inline;
                line-height: initial; }

            ol[inline-list] li {
                margin: 0;
                padding: 0;
                text-indent: 0;
                display: inline;
                line-height: initial; }

            // for new document display
            .index {
                min-width: $nodeMargin;
                margin-right: $std-space;
                display: inline-block;
                text-align: left;
                text-indent: 0; }

            // Titles
            .document > .title {
                font-weight: bold;
                text-align: center;
                margin-bottom: 1em;
                text-transform: uppercase; }

            .section > .title {
                margin-bottom: 1em;
                text-indent: $nodeMargin; }

            .section > .title::after {
                content: "."; } // TODO: should be done server side?

            .section .index {
                min-width: $nodeMargin * 3;
                display: inline-block;
                text-align: left;
                text-indent: 0; }

            .article > .title, .recitals > .title {
                text-align: center;
                margin-bottom: 1em;
                text-transform: uppercase; }

            .article > .title > .index {
                display: block;
                text-align: center; }

            .exhibit > .title {
                margin-bottom: 1em;
                font-weight: bold;
                text-align: center; }

            .xref {
                color: $orange;
                text-decoration: underline;
                cursor: pointer; }

            .noticeBlock {
                margin-left: $nodeMargin;
                margin-bottom: 16px;

                .noticeBefore {
                    width: $nodeMargin * 5; }

                .noticeBody {
                    margin-left: $nodeMargin; }

                .noticeBefore, .noticeBody {
                    display: inline-block;
                    vertical-align: top; } }

            // mimic the template page
            .cover, .notice, .sectionBreak, .signature, .tableOfContents {
                text-align: center;
                font-style: italic; }

            // based on inline node name
            .inlineBox {
                .nodeNumberingIndex {
                    min-width: initial; } } }

        // to handle non-styled old documents
        .isOld {
            p, li, div {
                margin-bottom: $std-space * 2; } } } }

.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1; }

.docVariantPopper {

    .popper {
        overflow: hidden;
        padding: 0px;
        max-height: unset;
        max-width: unset;
        border-radius: 6px;
        box-shadow: 1px 1px 28px rgba(0, 0, 0, 0.1);
        z-index: 9999999;

        .popperCloseX {
            font-size: 1.5em; } } }

.openedPopper:before {
    content: '';
    display: block;
    background: rgba(7, 8, 28, 0.25);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 9999999; }

.docVariantMain {
    width: 850px;
    height: 660px;
    background-color: white;

    .heading {
        background-color: #E4853B;
        height: 58px;
        padding: 20px 24px;
        color: white;
        text-align: center;

        .font {
            font-size: 20px; } }

    .body {
        height: 82%;
        width: 100%;
        padding: 24px;
        position: relative; }

    .downloadBtn {
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;

        .btnSize {
            width: 50%; }

        .transparentBtn {
            background-color: transparent;
            color: #d66e03; } } }

.clearRedline {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #516F90;
    text-align: center; }

.verticalBar {
    position: absolute;
    left: 45.83%;
    right: -12.5%;
    top: 83.33%;
    bottom: 8.33%;
    background: #E2EBFA;
    transform: rotate(-90deg); }

.angleIcon {
    color: #516F90; }

.showEdits {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: #FFFFFF !important; }

.editMessage {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 8px;
    position: relative;
    bottom: 0px;
    margin-left: 40px;
    color: #FFFFFF; }

.document > div.p {
    word-break: break-word; }

.fileNameFormatRow {
    display: flex;
    flex-direction: row;
    align-items: center; }

.fileFormatText {
    color: $orange;
    width: 170px;
    padding: 5px; }

.fileFormatText:hover {
    color: $orange;
    background-color: #ffe4e1;
    border-radius: 10px;
    text-decoration: none; }

.exportTableDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    margin-left: 2%; }

.exportTableText {
    color: $orange;
    padding: 5px; }

.exportTableText:hover {
    color: $orange;
    background-color: #ffe4e1;
    border-radius: 10px;
    text-decoration: none; }

.exportInfoMessage {
    background-color: $white;
    position: absolute;
    bottom: 30px;
    margin-left: -25px;
    border-radius: 10px;
    z-index: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16);
    width: 240px;
    height: 65px;
    padding: 5px; }

.exportInfoTextMessage {
    font-size: 12px;
    text-align: left;
    color: #7B7B7B; }

.filePrefixOptionsPopper {

    .popper {
        z-index: 999999999;
        width: 600px;
        padding: 22px; } }

.headingText {
    margin-bottom: 15px;
    display: flex;
    align-items: center; }

.headingText p {
    margin: 0;
    font-size: 22px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 100;
    color: $sharePopper-dk-grey; }

.icon {
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background-color: $sideNav-dk-orange;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-right: 16px; }

.icon  img {
    width: 100%;
    height: 100%; }

.descriptionLabelText {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 400;
    color: $sharePopper-dk-grey;
    letter-spacing: 0.18px;
    margin-bottom: 8px; }

.optionsArea {
    width: 100%;
    height: 300px;
    border: 1px solid #707070;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth; }

.optionsArea::-webkit-scrollbar {
    width: 4px;
    display: block; }

.optionsArea::-webkit-scrollbar-track {
    background: $lt-blue; }

.fileNamePrefixOptions {
    cursor: pointer;
    font-size: 16px;
    line-height: 23px;
    font-weight: 100;
    font-family: "Mulish", sans-serif;
    color: #606060;
    background-color: $white;
    height: 35px;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    display: flex; }

.fileNamePrefixOptions:hover {
    color: #F66322; }

.fileNamePrefixOptionsSelected {
    cursor: pointer;
    font-size: 16px;
    line-height: 23px;
    font-weight: 100;
    font-family: "Mulish", sans-serif;
    color: $white;
    background-color: #F66322;
    height: 35px;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    display: flex; }

.doneBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 0px 0px;
    margin-right: -15px;
    width: 100px;
    text-align: center;
    height: 40px; }

.cancelBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 0px 0px;
    margin-right: 20px;
    background: $white;
    color: #F66322;
    width: 100px;
    text-align: center;
    height: 40px; }

.simpleText {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: $sharePopper-dk-grey;
    letter-spacing: 0.18px;
    margin-bottom: 10px; }

.radioInputRow {
    display: flex;
    align-items: center;
    flex-direction: row; }

.radioInput {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 55px; }

.radioInputLabel {
    font-size: 15px;
    color: #606060;
    margin-left: 10px;
    margin-top: 18px; }

.radioOptionUnselected {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #707070;
    cursor: pointer; }

.radioOptionUnselected:hover {
    transform: scale(1.10); }

.radioOptionSelected {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 5px solid #F66322; }

.popperOverlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999999; }

#searchableDropdownInput {
    font-size: 16px;
    width: fit-content;
    min-width: 100%;
    height: 32.5px;
    padding: 14px 20px 12px 45px;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    font-size: 1rem;
    line-height: 1.5;
    color: transparent;
    background-color: #fff;
    caret-color: tranparent; }

#searchableDropdownInput::-moz-placeholder {
    color: #5A6070; }

#searchableDropdownInput:focus {
    outline: 3px solid #CDDEFF;
    background-color: #fff;
    caret-color: transparent; }

.searchableDropdown {
    position: relative;
    display: inline-block;
    width: fit-content;
    min-width: 100%; }

.searchableDropdownContent {
    position: absolute;
    background-color: #FFFFFF;
    width: fit-content;
    min-width: 100%;
    height: 300px;
    z-index: 1;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    scroll-behavior: smooth;
    overflow-y: scroll; }

.searchableDropdownContent::-webkit-scrollbar {
    width: 4px;
    display: block; }

.searchableDropdownContent::-webkit-scrollbar-track {
    background-color: $lt-blue; }

.searchableDropdownContentUnselected {
    color: #5A6070;
    padding: 5px 16px;
    text-decoration: none;
    display: block; }

.searchableDropdownContentUnselected:hover {
    color: #F66322;
    text-decoration: none; }

.searchableDropdownContentSelected {
    background-color: #F66322;
    color: #FFFFFF;
    text-decoration: none;
    padding: 5px 16px;
    display: block; }

.searchableDropdownContentSelected:hover {
    color: #FFFFFF;
    text-decoration: none; }

.clearRedLineButton {
    padding: 0px 0px;
    padding-top: 3px;
    width: 120px;
    text-align: center;
    height: 30px;
    border-radius: 5px;
    background-color: #FAE7DB !important;
    color: #4c5261;
    font-size: 14px; }

.clearRedLineButton:hover {
    background-color: #FFFFFF !important; }

.editsArrowIcons {
    width: 30px;
    height: 30px; }

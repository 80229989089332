@import "colors";
@import "util";
@import "spacing";

#docNav {
    width: fit-content;
    min-width: 460px;
    .openableHeading {
        position: relative; }

    .canOpen:hover {}

    .canOpen .title:hover {
        text-decoration: underline;
        cursor: pointer; }

    .isOpen {
        @include rounded; }

    .heading {
        font-size: 17px;
        letter-spacing: .3px; }

    .heading {
        font-weight: 500; }

    .sectionPane .heading {
        font-weight: normal; }

    .sectionPane .heading .index {
        min-width: 16px; }

    .sectionPane .sectionPane .heading {
        font-weight: normal;
        font-style: italic; }
    .navLevelHeading {
        background-color: $lt-blue;
        padding: 8px 8px 8px 14px;
        margin-bottom: 10px;
        border-radius: 4px; }

    .navLevelHeading.isOpen {
        margin-bottom: 0; }

    .navLevelHeading .warningIcon {
        padding-left: 4px; }

    .sectionPane  .navLevelHeading {
        background: transparent; }

    *[disabled] {
        color: $lt-grey !important;
        cursor: default; }

    .tab {
        padding-top: 8px; }

    .auto-bias {
        font-weight: normal;
        font-style: normal; }

    .bias {
        margin: 0;
        color: $blue;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 16px;
        color: $grey;
        padding: 2px 5px;
        border-radius: 4px; }

    .activeBias {
        color: $orange;
        background-color: $white; }

    .canOpen:hover .activeBias {
        color: $dk-orange; }

    .bias:hover {
        color: $text;
        background: $white;
        text-decoration: none; }

    .optionalOption {
        .custom-control-label {
            margin: 0;
            min-height: 1em; }

        .custom-control-label:hover {
            cursor: pointer; } }

    .heading span {
        padding: 0; }

    .heading {
        padding: 0; }

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;

        .canExpand {
            flex-basis: 2 * $std-space;
            flex-grow: 0;
            flex-shrink: 0;
            color: $grey;
            font-size: 10px;
            line-height: 24px;
            i {
                vertical-align: baseline; } }

        .option {
            line-height: normal; }

        .index {
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 14px;
            color: $orange;
            min-width: 16px; }

        .title {
            flex-grow: 1;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase; }

        .optionalOptionSpacer {
            flex-basis: 14px;
            flex-grow: 0;
            flex-shrink: 0; } } }

.documentLevelBiases {
    background-color: $lt-blue;
    padding: 8px 8px 8px 14px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }

.documentLevelBiasesTitle {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase; }

@import "colors";

#docPane {
    li[removed] > *:before {
        content: "( )" !important;
        counter-increment: removed-counter !important; }

    *[added], #docPane *[added] * {
        color: $blue !important; }

    *[removed], #docPane *[removed] * {
        color: $red !important;
        text-decoration: line-through; }

    // new system
    .added {
        color: $blue !important; }

    .removed {
        color: $red !important;
        text-decoration: line-through; } }

#sigPageFollows {
    text-align: center;
    margin-bottom: 5em;
    span {
        font-style: italic; } }

.sigBlock {
    width: 50%;
    margin-top: 3em; }

.sigName {
    margin-bottom: 2em; }

#sigPage {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-end;
    align-items: flex-end; }

@import "colors";

.selectOrg {
    background-color: $off-white;
    padding: 8px;

    .row {
        margin: 0; }

    .orgToSelect:hover {
        background-color: $blue;
        color: white;
        cursor: pointer; } }
